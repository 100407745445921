import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FormattedMessage } from 'react-intl';

@observer
class TariffMinDays extends Component {
  render() {
    const { labels, days, billing_hour, tariff } = this.props;

    if (tariff.type === 'base' || tariff.type === 'tour') {
      return null;
    }

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>
            {labels.stay_period}
          </span>

          <span className='value'>
            {days > 0
              ? <FormattedMessage
                  id='stay_period.min_days_with_count'
                  values={{ count: days, billing_hour: billing_hour }}
                />
              : <FormattedMessage id='tariff.not_specified' />}
          </span>
        </div>
      </div>
    );
  }
}

TariffMinDays.propTypes = {
  days: PropTypes.number.isRequired,
  billing_hour: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  tariff: PropTypes.object.isRequired
};

export default TariffMinDays;
