import _range from 'lodash/range';
import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'id',
  'type',
  'name',
  'name_translations.ru',
  'name_translations.en',
  'description_translations.ru',
  'description_translations.en',
  'rates[]',
  'rates[].id',
  'rates[].type',
  'rates[].age_group',
  'rates[].age_period',
  'rates[].age_period.from',
  'rates[].age_period.to',
  'rates[].occupation',
  'rates[].description',
  'rates[].price',
  'room_type',
  'room_type.id',
  'room_type.name',
  'room_type.beds',
  'room_type.extra_beds',
  'room_type.count',
  'available_period',
  'available_period.from',
  'available_period.to',
  'visible_period',
  'visible_period.from',
  'visible_period.to',
  'stop_sales[]',
  'stop_sales[].from',
  'stop_sales[].to',
  'source',
  'source.id',
  'source.name',
  'source.settings',
  'source.settings.booking_expiration_time',
  'source.settings.should_be_confirmed',
  'source.settings.has_cancellation_policy',
  'insurant',
  'insurant.id',
  'insurant.name',
  'insurant.short_name',
  'insurant.inn',
  'insurant.kpp',
  // 'periods[]',
  // 'periods[].id',
  // 'periods[].start_date',
  // 'periods[].end_date',
  // 'periods[].rates[].description',
  // 'periods[].rates[].price',
  // 'periods[].rates[].age_group',
  // 'periods[].rates[].age_period.from',
  // 'periods[].rates[].age_period.to',
  // 'periods[].rates[].occupation',
  'stay_period',
  'stay_period.min_days',
  'price_calculation_rule',
  'billing_hour',
  'cancellation_policy',
  'cancellation_policy.has_full_refund',
  'cancellation_policy.has_non_refund',
  'cancellation_policy.policy_rules[]',
  'cancellation_policy.policy_rules[].penalty',
  'cancellation_policy.policy_rules[].penalty.amount',
  'cancellation_policy.policy_rules[].penalty.unit',
  'cancellation_policy.policy_rules[].deadline',
  'cancellation_policy.policy_rules[].deadline.amount',
  'cancellation_policy.policy_rules[].deadline.unit',
  'release_period',
  'release_period.count',
  'feed_type',
  'feed_type.name',
  'feed_type.tag',
  'meal_option',
  'meal_option.name',
  'meal_option.tag',
  'meal_disclaimer',
  'meal_disclaimer_translations.ru',
  'meal_disclaimer_translations.en',
  'manipulation',
  'manipulation.is_manual',
  'manipulation.disclaimer',
  'manipulation.disclaimer_translations.ru',
  'manipulation.disclaimer_translations.en',
  'manipulation.quantity',
  'manipulation.options[]',
  'manipulation.options[].name',
  'manipulation.options[].name_translations.ru',
  'manipulation.options[].name_translations.en',
  'service',
  'service.disclaimer',
  'service.disclaimer_translations.ru',
  'service.disclaimer_translations.en',
  'service.options[]',
  'service.options[].name',
  'service.options[].name_translations.ru',
  'service.options[].name_translations.en',
  'promo',
  'promo.name',
  'promo.tag',
  'promo.target',
  'promo.applied_percent_off',
  'promo.disclaimer',
  'promo.disclaimer_translations.ru',
  'promo.disclaimer_translations.en',
  'length_of_stay_restrictions[]',
  'length_of_stay_restrictions[].start_date',
  'length_of_stay_restrictions[].end_date',
  'length_of_stay_restrictions[].min_length_of_stay',
  'length_of_stay_restrictions[].max_length_of_stay',
  'advance_booking_restrictions[]',
  'advance_booking_restrictions[].start_date',
  'advance_booking_restrictions[].end_date',
  'advance_booking_restrictions[].min_days_before_check_in',
  'advance_booking_restrictions[].max_days_before_check_in',
  'length_of_stay_arrival_based_restrictions[]',
  'length_of_stay_arrival_based_restrictions[].start_date',
  'length_of_stay_arrival_based_restrictions[].end_date',
  'length_of_stay_arrival_based_restrictions[].min_length_of_stay',
  'length_of_stay_arrival_based_restrictions[].max_length_of_stay',
  'closed_by_date_restrictions[]',
  'closed_by_date_restrictions[].start_date',
  'closed_by_date_restrictions[].end_date',
  'closed_by_date_restrictions[].closed_to_arrival',
  'closed_by_date_restrictions[].closed_to_departure',
  'closed_by_week_day_restrictions[]',
  'closed_by_week_day_restrictions[].start_date',
  'closed_by_week_day_restrictions[].end_date',
  'closed_by_week_day_restrictions[].week_day_number',
  'closed_by_week_day_restrictions[].closed_to_arrival',
  'closed_by_week_day_restrictions[].closed_to_departure',
  'contract',
  'contract.commission_in_percent',
  'contract.number',
  'contract.from_date'
];

const labels = {
  type: formatMessage('tariff.type'),
  name: formatMessage('tariff.rate_name'),
  'name_translations.ru': formatMessage('tariff.rate_name'),
  'name_translations.en': formatMessage('tariff.rate_name'),
  description: formatMessage('tariff.description'),
  'description_translations.ru': formatMessage('tariff.description'),
  'description_translations.en': formatMessage('tariff.description'),
  rates: formatMessage('rates.self'),
  room_type: formatMessage('tariff.room_type'),
  'room_type.id': formatMessage('tariff.room_type'),
  available_period: formatMessage('tariff.available_period'),
  'available_period.from': formatMessage('tariff.available_period'),
  'available_period.to': formatMessage('tariff.available_period'),
  visible_period: formatMessage('tariff.effective_period'),
  'visible_period.from': formatMessage('tariff.effective_period'),
  'visible_period.to': formatMessage('tariff.effective_period'),
  stop_sales: formatMessage('tariff.stop_sales_period'),
  'stop_sales[].from': formatMessage('tariff.stop_sales_period'),
  'stop_sales[].to': formatMessage('tariff.stop_sales_period'),
  'stay_period.min_days': formatMessage('tariff.stay_period'),
  source: formatMessage('tariff.source'),
  insurant: formatMessage('tariff.insurant'),
  // 'periods[].start_date': formatMessage('periods.start_date'),
  // 'periods[].rates': formatMessage('periods.rates'),
  'release_period.count': formatMessage('tariffs.release_period.label'),
  feed_type: formatMessage('tariffs.feed_types.self'),
  promo: formatMessage('tariffs.promo.description'),
  meal_option: formatMessage('tariffs.meal_options.self'),
  meal_disclaimer: formatMessage('tariffs.meal_disclaimer.self'),
  'meal_disclaimer_translations.ru': formatMessage('tariffs.meal_disclaimer.self'),
  'meal_disclaimer_translations.en': formatMessage('tariffs.meal_disclaimer.self'),
  manipulation: formatMessage('tariffs.manipulation.self'),
  'manipulation.is_manual': formatMessage('tariffs.manipulation.is_manual'),
  'manipulation.disclaimer': formatMessage('tariffs.manipulation.disclaimer'),
  'manipulation.disclaimer_translations.ru': formatMessage('tariffs.manipulation.disclaimer'),
  'manipulation.disclaimer_translations.en': formatMessage('tariffs.manipulation.disclaimer'),
  'manipulation.quantity': formatMessage('tariffs.manipulation.quantity'),
  'manipulation.options': formatMessage('tariffs.manipulation.options'),
  'service.disclaimer': formatMessage('tariffs.service.disclaimer'),
  'service.disclaimer_translations.ru': formatMessage('tariffs.service.disclaimer'),
  'service.disclaimer_translations.en': formatMessage('tariffs.service.disclaimer'),
  'service.options': formatMessage('tariffs.service.options'),
  'promo.applied_percent_off': formatMessage('tariffs.promo.quantity'),
  'promo.disclaimer': formatMessage('shared.disclaimer'),
  'promo.disclaimer_translations.ru': formatMessage('shared.disclaimer'),
  'promo.disclaimer_translations.en': formatMessage('shared.disclaimer'),
  'contract.commission_in_percent': formatMessage('tariffs.contract.commission'),
  'contract.number': formatMessage('tariffs.contract.number'),
  'contract.from_date': formatMessage('tariffs.contract.from_date')
};

const placeholders = {
  name: '',
  'name_translations.ru': 'Введите значение',
  'name_translations.en': 'Enter name',
  'description_translations.ru': 'Введите описание',
  'description_translations.en': 'Enter description',
  'rates[].price': 'руб.',
  'room_type.id': formatMessage('shared.choose'),
  'available_period.from': formatMessage('tariff.not_specified'),
  'available_period.to': formatMessage('tariff.not_specified'),
  'visible_period.from': formatMessage('tariff.not_specified'),
  'visible_period.to': formatMessage('tariff.not_specified'),
  'stop_sales[].from': formatMessage('tariff.not_specified'),
  'stop_sales[].to': formatMessage('tariff.not_specified'),
  'stay_period.min_days': formatMessage('tariff.not_specified'),
  source: formatMessage('shared.choose'),
  insurant: formatMessage('shared.choose'),
  'services[].service_id': formatMessage('shared.choose'),
  // 'periods[].start_date': formatMessage('periods.date_not_defined'),
  // 'periods[].end_date': formatMessage('periods.date_not_defined'),
  'release_period.count': formatMessage('tariff.not_specified'),
  meal_disclaimer: formatMessage('tariff.meal_disclaimer.not_specified'),
  'meal_disclaimer_translations.ru': formatMessage('tariffs.meal_disclaimer.self'),
  'meal_disclaimer_translations.en': formatMessage('tariffs.meal_disclaimer.self'),
  'manipulation.disclaimer': formatMessage('tariffs.manipulation.disclaimer_hint'),
  'manipulation.disclaimer_translations.ru': formatMessage('tariffs.manipulation.disclaimer_hint'),
  'manipulation.disclaimer_translations.en': formatMessage('tariffs.manipulation.disclaimer_hint'),
  'service.disclaimer': formatMessage('tariffs.service.disclaimer_hint'),
  'service.disclaimer_translations.ru': formatMessage('tariffs.service.disclaimer_hint'),
  'service.disclaimer_translations.en': formatMessage('tariffs.service.disclaimer_hint'),
  'promo.target': formatMessage('tariffs.promo.description'),
  'promo.disclaimer': formatMessage('tariffs.promo.disclaimer'),
  'promo.disclaimer_translations.ru': formatMessage('tariffs.promo.disclaimer'),
  'promo.disclaimer_translations.en': formatMessage('tariffs.promo.disclaimer'),
  'contract.commission_in_percent': 'Введите комиссию',
  'contract.number': 'Введите номер договора',
  'contract.from_date': 'Выберите дату'
};

const rules = {
  type: 'string|required_with:base,tour,package',
  'name_translations.ru': 'string|required',
  'name_translations.en': 'string',
  'description_translations.ru': 'string|required',
  'description_translations.en': 'string',
  rates: 'array|required|min:1',
  'rates[].price': 'numeric|required',
  'rates[].age_group': 'string|required',
  'rates[].age_period.from': 'integer',
  'rates[].age_period.to': 'integer',
  'rates[].occupation': 'string|required',
  'room_type.id': 'string|required',
  available_period: 'required',
  'available_period.from': 'string|required',
  'available_period.to': 'string|required',
  'visible_period.from': 'string',
  'visible_period.to': 'string',
  // 'periods[].start_date': 'required',
  // 'periods[].end_date': 'required',
  // 'periods[].rates[].price': 'numeric|required',
  'cancellation_policy.policy_rules[].deadline.amount': 'numeric|required|min:0|max:180',
  'cancellation_policy.policy_rules[].penalty.amount': 'numeric|required|min:0',
  price_calculation_rule: 'string|required',
  'release_period.count': 'numeric|required|min:0|max:180',
  'promo.target': 'string',
  'promo.applied_percent_off': 'numeric|required|min:0|max:100',
  'service.disclaimer': '',
  'service.disclaimer_translations.ru': '',
  'service.disclaimer_translations.en': '',
  'promo.disclaimer_translations.ru': 'string|required_with:promo.disclaimer_translations.en',
  'promo.disclaimer_translations.en': 'string',
  'manipulation.disclaimer_translations.ru': 'string|required_with:manipulation.disclaimer_translations.en',
  'manipulation.disclaimer_translations.en': 'string',
  'meal_disclaimer_translations.ru': 'string|required_with:meal_disclaimer_translations.en',
  'meal_disclaimer_translations.en': 'string',
  'meal_option.tag': 'string|required_with:meal_disclaimer_translations.ru',
  'contract.commission_in_percent': 'numeric|min:0|max:100|required',
  'contract.number': 'string|required',
  'contract.from_date': 'string|required'
};

const extra = {
  type: [{
    key: 'base',
    name: formatMessage('tariffs.base.name'),
    description: formatMessage('tariffs.base.description')
  }, {
    key: 'tour',
    name: formatMessage('tariffs.tour.name'),
    description: formatMessage('tariffs.tour.description')
  }, {
    key: 'package',
    name: formatMessage('tariffs.package.name'),
    description: formatMessage('tariffs.package.description')
  }],
  'cancellation_policy.policy_rules[].penalty.unit': [{
    value: 'percent',
    label: '%'
  }, {
    value: 'rub',
    label: formatMessage('shared.rub')
  }, {
    value: 'first_day',
    label: formatMessage('shared.first_day')
  }],
  'manipulation.quantity': _range(100),
  promo: {
    target: [
      {
        value: 'promo',
        label: formatMessage('tariffs.promo.promo')
      },
      {
        value: 'new_year',
        label: formatMessage('tariffs.promo.new_year')
      },
      {
        value: 'covid',
        label: formatMessage('tariffs.promo.covid')
      }
    ]
  },
  closed_by_week_day_restrictions: {
    week_day_number: [
      {
        value: 0,
        label: formatMessage('tariff.closed_by_weekday_restrictions.weekday.list.mon')
      },
      {
        value: 1,
        label: formatMessage('tariff.closed_by_weekday_restrictions.weekday.list.tue')
      },
      {
        value: 2,
        label: formatMessage('tariff.closed_by_weekday_restrictions.weekday.list.wed')
      },
      {
        value: 3,
        label: formatMessage('tariff.closed_by_weekday_restrictions.weekday.list.thu')
      },
      {
        value: 4,
        label: formatMessage('tariff.closed_by_weekday_restrictions.weekday.list.fri')
      },
      {
        value: 5,
        label: formatMessage('tariff.closed_by_weekday_restrictions.weekday.list.sat')
      }
    ]
  }
};

const defaults = {
  type: 'base',
  release_period: {
    count: 0
  },
  'manipulation.is_manual': true,
  'manipulation.options': [],
  'service.options': [],
  promo: {
    applied_percent_off: 0
  },
  length_of_stay_restrictions: {
    min_length_of_stay: 1,
    max_length_of_stay: 8
  },
  stay_period: {
    min_days: 0
  },
  contract: {
    commission_in_percent: '',
    number: '',
    from_date: ''
  }
};

const values = {
  type: 'base',
  cancellation_policy: {
    has_full_refund: true
  },
  price_calculation_rule: 'float',
  release_period: {
    count: 0
  },
  'manipulation.is_manual': true,
  'manipulation.options': [],
  'service.options': [],
  promo: {
    applied_percent_off: 0
  },
  length_of_stay_restrictions: {
    min_length_of_stay: 1,
    max_length_of_stay: 8
  },
  stay_period: {
    min_days: 0
  },
  contract: {
    commission_in_percent: '',
    number: '',
    from_date: ''
  }
};

const types = {
  'manipulation.is_manual': 'checkbox',
  promo: {
    applied_percent_off: 'number'
  },
  'contract.commission_in_percent': 'number',
  'contract.number': 'string',
  'contract.from_date': 'date'
};

export default {
  fields,
  labels,
  extra,
  placeholders,
  rules,
  values,
  defaults,
  types
};
