import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import PageBody from './components/PageBody';
import LanguageControl from 'Shared/components/LanguageFormControl';
import PropTypes from 'prop-types';

import { UserProvider } from 'Context/FormLanguageContext';

import FormState, {
  baseFields,
  tourFields,
  packageFields
} from 'Pages/Tariffs/components/FormState';

import _merge from 'lodash/merge';
import _uniqId from 'lodash/uniqueId';
import _get from 'lodash/get';

import Buttons from './components/Actions/Buttons';

const DEFAULT_BILLING_HOUR = 'night';

@inject('hotelStore', 'tariffsStore')
@observer
class Add extends Component {
  @observable form = undefined;

  state = {
    language: 'ru'
  };

  componentDidMount() {
    this.changeTypeHandler = reaction(
      () => this.form && this.form.$('type').value,
      (type) => this.initForm(type),
      { fireImmediately: true }
    );

    this.scrollToTop();
  }

  componentWillUnmount() {
    this.changeTypeHandler();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  initForm = (type) => {
    // Initialize form
    let fields;

    if (type === 'tour') {
      fields = tourFields;
    } else if (type === 'package') {
      fields = packageFields;
    } else {
      fields = baseFields;
    }

    this.form = new FormState(fields);

    // Populate data from state
    let { state } = this.props.history.location;
    state = state || {};

    let { values } = state;

    if (values) {
      if (_get(values, 'rates', false)) {
        values.rates = values.rates.map((rate) => {
          rate.id = _uniqId();
          return rate;
        });
      }
      const { periods, room_type, ...attrs } = values;

      values = _merge(attrs, {
        type: type,
        billing_hour: DEFAULT_BILLING_HOUR,
        room_type: {
          ...room_type,
          name: room_type.name
        }
      });
      this.form.update(values);
      this.form.setPeriods(periods || []);
    } else {
      this.form.update({
        billing_hour: DEFAULT_BILLING_HOUR,
        room_type: {
          name: ''
        }
      });
      this.form.setPeriods([]);
    }
  };

  handleLanguageChange = (language) => {
    if (this.state.language === language) return;

    this.setState({ language });
  };

  render() {
    const { tariffsStore, hotelStore } = this.props;

    if (!hotelStore.isFetched) {
      return null;
    }

    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.handleLanguageChange
        }}
      >
        <div className="layout__page">
          <div className="layout__page-form-controls">
            <TopBar>
              <TopBarContent />
            </TopBar>

            <div className="layout__language-action">
              <div className="language__control-content">
                <div className="control">
                  <LanguageControl onClick={this.handleLanguageChange} />
                </div>
              </div>
            </div>
          </div>

          {this.form && (
            <>
              <div className="layout__page-content">
                <PageBody
                  form={this.form}
                  hotelBillingHour={DEFAULT_BILLING_HOUR}
                  hotelSources={hotelStore.hotel.sources}
                  isCreate
                />
              </div>

              <Buttons form={this.form} tariffsStore={tariffsStore} />
            </>
          )}
        </div>
      </UserProvider>
    );
  }
}

Add.propTypes = {
  tariffsStore: PropTypes.object.isRequired,
  hotelStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Add;
