import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TariffMinDays from './TariffMinDays';

@observer
class TariffStayPeriodBlock extends Component {
  render() {
    const { labels, tariff } = this.props;
    const { billing_hour, stay_period } = tariff;

    return (
      <div className='row'>
        <div className='col col-12'>
          <TariffMinDays
            days={stay_period.min_days}
            billing_hour={billing_hour}
            labels={labels}
            tariff={tariff}
          />
        </div>
      </div>
    );
  }
}

TariffStayPeriodBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffStayPeriodBlock;
