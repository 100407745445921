import { types, applySnapshot, resolveIdentifier } from 'mobx-state-tree';
import instance from 'connection/instance';
import { objectClean } from 'Utils/objectClean';
import cleanDeep from 'clean-deep';
import moment from 'moment';

import { Tariff } from './Tariff';
import { TariffsMeta } from './TariffsMeta';
import { RoomType } from './RoomType';

const TariffsNewStore = types.model(
  'TariffsNewStore',
  {
    tariffs: types.optional(types.array(Tariff), []),
    meta: types.optional(TariffsMeta, {}),
    state: types.optional(
      types.enumeration(['pending', 'done', 'error']),
      'done'
    ),

    get isFetched() {
      return this.state === 'done';
    },

    get isPending() {
      return this.state === 'pending';
    },

    get isError() {
      return this.state === 'error';
    },

    get roomTypeOptions() {
      return this.meta.room_types.map(item => {
        return { value: item.id, label: item.name };
      });
    },

    get sourcesOptions() {
      return this.meta.sources.map(item => {
        return { value: item.id, label: item.name };
      });
    }
  },
  {
    fetch(params = {}) {
      this.setState('pending');
      const tariff = objectClean(params);

      instance
        .get('/api/tariffs', { params: { tariff } })
        .then(response => this.resetStore(response))
        .then(() => this.setState('done'))
        .catch(error => this.errorHandler(error));
    },

    fetchOne(id, params = {}) {
      this.setState('pending');

      instance
        .get(`/api/tariffs/${id}`, { params: params })
        .then(response => this.addToCollection(response))
        .then(response => this.setState('done', response))
        .catch(error => this.errorHandler(error));
    },

    saveTariff(form) {
      this.setState('pending');

      let data = cleanDeep({ ...form.values() });
      data = cleanDeep(data, { emptyArrays: false });

      if (data.contract?.from_date) {
        data.contract.from_date = moment(data.contract.from_date)
          .format('YYYY-MM-DDTHH:mm:ssZ');
      }

      data = {
        ...data,
        periods: form.periods.length ? cleanDeep([...form.periods]) : [],
        service: {
          disclaimer_translations: {
            ru: data?.service?.disclaimer_translations?.ru || ' ',
            en: data?.service?.disclaimer_translations?.en || ' '
          } || {},
          options: data?.service?.options || []
        } || {}
      };

      data = { tariff: data };

      const promise = this.createTariff(data);

      return promise
        .then(response => this.setState('done', response))
        .catch(error => this.errorHandler(error));
    },

    createTariff(data) {
      return instance
        .post('/api/tariffs', data);
    },

    updateTariff(id, data) {
      return instance
        .put(`/api/tariffs/${id}`, data)
        .then(response => this.addToCollection(response));
    },

    find(tariff_id) {
      return resolveIdentifier(Tariff, this.tariffs, tariff_id);
    },

    findRoomType(room_type_id) {
      return resolveIdentifier(RoomType, this.meta.room_types, room_type_id);
    },

    clear() {
      const data = { meta: {}, tariffs: [] };
      applySnapshot(this, data);
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(this, data);
        return data;
      }
    },

    setState(state, response = undefined) {
      this.state = state;
      return response;
    },

    errorHandler(error) {
      this.setState('error');
      return Promise.reject(error);
    },

    addToCollection(response) {
      const { data } = response;
      this.tariffs.push(data.tariff);

      return response;
    }
  }
);

export default TariffsNewStore;
