import { types, destroy, resolveIdentifier } from 'mobx-state-tree';
import _cloneDeep from 'lodash/cloneDeep';
import arrayToTree from 'array-to-tree';

import { Room, RoomType } from './Room';
import { Building } from './Building';
import { Specialization } from '../DiseasesStore/Specialization';
import { Amenity } from './Amenity';
import { TreatmentProfiles } from './TreatmentProfiles';
import { CardOption } from './CardOption';
import { Feed } from './Feed';
import { Translation } from './Translation';

export const HotelAddress = types.model('HotelAddress', {
  location: types.string,
  coordinates: types.array(types.number),
  location_translations: types.optional(Translation, {}),
  timezone: types.maybe(types.string)
});

export const Step = types.model(
  'Step',
  {
    id: types.identifier(types.number),
    name: types.string,
    text: types.string,
    isDisabled: types.optional(types.boolean, true),

    get path() {
      return ['/wizard', this.name].join('/');
    }
  },
  {
    enable() {
      this.isDisabled = false;
    },

    disable() {
      this.isDisabled = true;
    }
  }
);

export const StepInfo = types.model('StepInfo', {
  current_step: types.string,
  steps: types.array(Step)
});

export const ImageVersion = types.model('ImageVersion', {
  url: types.maybe(types.string)
});

export const Promo = types.model('Promo', {
  tag: types.maybe(types.string),
  // name: types.string,
  name_translations: types.optional(Translation, {})
});

export const Image = types.model(
  'Image',
  {
    id: types.identifier(types.string),
    url: types.maybe(types.string),
    encoded_url: types.maybe(types.string),
    thumb: types.maybe(ImageVersion),
    portrait: types.maybe(ImageVersion),
    landscape_big: types.maybe(ImageVersion),
    landscape_small: types.maybe(ImageVersion),
    priority: types.maybe(types.number),
    promo: types.maybe(Promo),
    alt_translations: types.maybe(Translation)
  },
  {
    destroy() {
      destroy(this);
    },

    setPromo(promo) {
      if (promo) {
        this.promo = { ...promo };
      } else {
        this.promo = null;
      }
    },

    setPriority(priority) {
      this.priority = priority;
    }
  }
);

export const Settings = types.model('Settings', {
  check_in_time: types.string,
  early_check_in_time: types.string,
  check_out_time: types.string,
  late_check_out_time: types.string,
  children_from: types.number,
  booking_expiration_time: types.maybe(types.number),
  billing_hour: types.maybe(types.enumeration(['night', 'day']))
});

export const PaymentByCard = types.model('PaymentByCard', {
  allow: types.boolean,
  mastercard_card: types.boolean,
  visa_card: types.boolean,
  mir_card: types.boolean
});

export const PaymentByCash = types.model('PaymentByCash', {
  allow: types.boolean
});

export const ByPrepayment = types.model('ByPrepayment', {
  allow: types.boolean,
  percent: types.number
});

export const PaymentDetails = types.model('PaymentDetails', {
  id: types.identifier(types.string),
  by_card: PaymentByCard,
  by_cash: PaymentByCash,
  by_prepayment: types.optional(ByPrepayment, { allow: false, percent: 10 })
});

export const Contact = types.model('Contact', {
  type: types.string,
  value: types.string,
  suffix: types.maybe(types.number),
  text: types.maybe(types.string),
  send_notification: types.optional(types.boolean, false)
});

export const Insurance = types.model('Insurance', {
  id: types.identifier(types.string),
  name: types.string
});

export const SourceSettings = types.model('SourceSetting', {
  id: types.identifier(types.string),
  billing_hour: types.maybe(types.enumeration(['night', 'day'])),
  check_in_time: types.string,
  check_out_time: types.string,
  early_check_in_time: types.string,
  late_check_out_time: types.string
});

export const Source = types.model('Source', {
  id: types.identifier(types.string),
  searchable: types.boolean,
  insurance: Insurance,
  settings: SourceSettings,
  contract: types.frozen
});

export const Hotel = types.model(
  'Hotel',
  {
    id: types.identifier(types.string),
    name: types.optional(types.string, ''),
    name_translations: types.optional(Translation, {}),
    address: types.maybe(HotelAddress),
    description: types.optional(types.string, ''),
    description_translations: types.optional(Translation, {}),
    web_site: types.maybe(types.string),
    email: types.string,
    phone: types.string,
    buildings: types.optional(types.array(Building), []),
    room_types: types.optional(types.array(RoomType), []),
    rooms: types.optional(types.array(Room), []),
    specializations: types.optional(types.array(Specialization), []),
    amenities: types.optional(types.array(Amenity), []),
    images: types.optional(types.array(Image), []),
    current_step: types.string,
    settings: Settings,
    payment_details: PaymentDetails,
    contacts: types.optional(types.array(Contact), []),
    currency: types.string,
    treatment_profiles: types.optional(types.array(TreatmentProfiles), []),
    card_options: types.optional(types.array(CardOption), []),
    star: types.optional(types.number, 0),
    feed_types: types.optional(types.array(Feed), []),
    meal_options: types.optional(types.array(Feed), []),
    location_types: types.optional(types.array(Feed), []),
    treatment_factors: types.optional(types.array(Feed), []),
    accommodation_features: types.optional(types.array(Feed), []),
    has_integration: types.optional(types.boolean, false),
    sources: types.optional(types.array(Source), []),

    get hasBuildings() {
      return this.buildings.length > 0;
    },

    get hasRoomTypes() {
      return this.room_types.length > 0;
    },

    get hasRooms() {
      return this.rooms.length > 0;
    },

    get isWizardCompleted() {
      return this.current_step === 'completed_step';
    }
  },
  {
    toForm() {
      let data = this.toJSON();
      data = _cloneDeep(data);

      const selectedNodes = [];

      const loop = (data) => {
        return data.map((item) => {
          return item.children
            ? loop(item.children)
            : selectedNodes.push(item.tag);
        });
      };

      loop(
        arrayToTree(data.amenities, {
          parentProperty: 'parent_tag',
          customID: 'tag'
        })
      );
      data.amenities = selectedNodes;

      return data;
    },

    findDisease(disease_id) {
      return resolveIdentifier(
        Specialization,
        this.specializations,
        disease_id
      );
    }
  }
);
