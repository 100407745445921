import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import Cookies from 'js-cookie';

import Type from './Type';
import Description from './Description';
import Info from './Info';
import FormItemInput from 'Shared/form/FormItemInput';
import SingleDateInput from 'Shared/form/SingleDateInput';

@inject('tariffsStore', 'hotelStore')
@observer
class Form extends Component {
  componentDidMount() {
    this.disposeReaction = reaction(
      () => this.props.form.$('source').value,
      async (selectedSource) => {
        if (selectedSource) {
          const { hotelSources, form, isCreate, hotelStore } = this.props;

          if (!isCreate) {
            return;
          }

          await hotelStore.fetch();

          const sources = hotelSources.toJSON();

          const source = sources.find(s =>
            s.insurance && s.insurance.name === selectedSource.name
          );

          if (source && source.contract) {
            form.update({
              contract: {
                number: source.contract.number || '',
                from_date: source.contract.from_date || '',
                commission_in_percent: source.contract.commission_in_percent
              }
            });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  render() {
    const { form } = this.props;
    const hasRestriction = Cookies.get('restriction');

    return (
      <form className="form">
        <div className="form__body">
          <div className="row">
            <div className="col col-12">
              <Type form={form} />
            </div>
          </div>

          <div className="row">
            <div className="col col-6">
              <Info form={form} />
            </div>

            <div className="col col-5 offset-1">
              <Description form={form} />
            </div>
          </div>

          {hasRestriction && (
            <div className="row">
              <div className="col col-6">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', visibility: 'hidden', height: 0 }}
                >
                  <FormItemInput field={form.$('contract.number')} isRequired />
                  <SingleDateInput
                    date={form.$('contract.from_date')}
                    customValidation={true}
                    isRequired
                    enableOutsideDays={true}
                  />
                </div>
                <FormItemInput
                  type="number"
                  field={form.$('contract.commission_in_percent')}
                  isRequired
                />
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired,
  hotelSources: PropTypes.object,
  hotelStore: PropTypes.object,
  isCreate: PropTypes.bool,
  tariffsStore: PropTypes.object.isRequired
};

export default Form;
