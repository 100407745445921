import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';

import { formatMessage } from 'Utils/formatMessage';

import Popover from 'Shared/ui/Popover';

import DownIcon from 'react-icons/lib/md/keyboard-arrow-down';

import Navigation from '../Navigation';
import Header from '../Header';
import Reservations from '../Reservations';
import Loader from '../Loader';
import Manager from '../Manager';
import CancellationPolicy from '../CancellationPolicy';

const Button = styled.button`
  margin-right: 0 !important;
`;

const IconContainer = styled.span`
  margin-left: 2px;
`;

const CancellationPolicyWrapper = styled.div`
  margin: 16px 0;
`;

@withRouter
@inject('orderStore', 'store')
@observer
class PageContent extends Component {
  @observable isDownload = false;

  setDownload = (type) => {
    this.isDownload = type;
  };

  unsetDownload = () => {
    this.isDownload = false;
  };

  handleDownloadDefault = (e) => {
    e.stopPropagation();

    this.setDownload('default');

    const { orderStore } = this.props;

    orderStore
      .download()
      .then((_r) => this.unsetDownload())
      .catch((error) => this.errorSubmitHandler(error) && this.unsetDownload());
  };

  handleDownloadSogaz = (e) => {
    e.stopPropagation();

    this.setDownload('sogaz');

    const { orderStore } = this.props;

    orderStore
      .download('registries')
      .then((_r) => this.unsetDownload())
      .catch((error) => this.errorSubmitHandler(error) && this.unsetDownload());
  };

  errorSubmitHandler = (error) => {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  };

  render() {
    const { parentRef } = this.props;
    const { isFetched, order } = this.props.orderStore;

    if (!isFetched) {
      return <Loader />;
    }

    const items = [
      {
        label: formatMessage('order.download.default'),
        onClick: this.handleDownloadDefault,
        loading: this.isDownload === 'default'
      },
      {
        label: formatMessage('order.download.sogaz'),
        onClick: this.handleDownloadSogaz,
        loading: this.isDownload === 'sogaz'
      }
    ];

    return (
      <div className="info__content">
        <Navigation />
        <Header />

        <div className="reservation-group">
          <div className="reservation-group__content">
            <Manager order={order} />
            {order.reservations?.length > 0 && (
              <CancellationPolicyWrapper>
                <CancellationPolicy
                  tariff={order.reservations[0].tariff}
                  cancellation_policy={
                    order.reservations[0].cancellation_policy
                  }
                />
              </CancellationPolicyWrapper>
            )}
            <Reservations reservations={order.reservations} />
          </div>
        </div>

        <div className="actions">
          <div className="group">
            <div className="float-left">
              <Link
                to={`/orders/${order.id}/conversation`}
                className="button gray"
              >
                <FormattedMessage id="orders.comments" />
              </Link>
            </div>

            <div className="float-right">
              <Popover items={items} parentElement={parentRef.current}>
                <Button className="button">
                  <FormattedMessage id="order.download.button" />

                  <IconContainer>
                    <DownIcon />
                  </IconContainer>
                </Button>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageContent;
