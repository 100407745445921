import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrderStore from 'Stores/OrdStore';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

import {
  OrderTableRowHeader,
  RowHeaderItem,
  OrderTableRowFooter,
  RowFooterItem,
  OrderTableRowContent
} from './Orders.styled';

import currencySymbolMap from 'Utils/currencySymbolMap';
import moment from 'Utils/moment';
import { withRouter } from 'react-router-dom';
import Loader from 'Pages/Buildings/components/Loader';

import store from 'store';
import getStatus from 'Pages/Orders/utils/getStatus';

@withRouter
@observer
class OrderRow extends Component {
  constructor(props) {
    super(props);

    this.orderStore = OrderStore.create();
  }

  componentDidMount() {
    this.fetchStore();
  }

  componentWillUnmount() {
    this.clearStore();
  }

  fetchStore = () => {
    const { id, filters } = this.props;

    this.orderStore.fetch(id, { filters });
  }

  clearStore = () => {
    this.orderStore.clear();
  }

  handleOpenInfo = (e) => {
    e.preventDefault();

    const { id, history } = this.props;
    const path = ['/orders', id, 'info'].join('/');

    history.push(path);
  }

  handleOpenComment = (e) => {
    e.preventDefault();

    const { id, history } = this.props;
    const path = ['/orders', id, 'conversation'].join('/');

    history.push(path);
  }

  render() {
    if (!this.orderStore.isFetched) {
      return <Loader />;
    }

    const { order } = this.orderStore;
    const generalLanguage = store.get('language');

    const listItems = (order.reservations || []).map((reservation, index) => {
      const {
        id,
        room_type,
        tariff,
        check_in,
        check_out,
        price: { amount, currency }
      } = reservation;

      const count = index + 1;
      const suffix = currencySymbolMap(currency);

      return (
        <OrderTableRowContent key={id}>
          <div>
            {count}. {room_type.name_translations[generalLanguage]} - {tariff.name_translations[generalLanguage]}
          </div>

          <div>
            {moment(check_in).format('DD.MM.YYYY')}
          </div>

          <div>
            {moment(check_out).format('DD.MM.YYYY')}
          </div>

          <div>
            {amount} {suffix}
          </div>

          <div>
            {formatMessage('orders.travellers_count', {
              count: reservation.state === 'cancelled'
                ? reservation.slots.length
                : reservation.slots.filter(slot => slot.state !== 'cancelled').length
            })}
          </div>

          <div />

          <div>
            <span className={getStatus(reservation.state).label}>
              {getStatus(reservation.state).text}
            </span>
          </div>
        </OrderTableRowContent>
      );
    });

    return (
      <>
        <OrderTableRowHeader>
          <RowHeaderItem>{formatMessage('orders.room_type_and_tariff')}</RowHeaderItem>
          <RowHeaderItem>{formatMessage('orders.check_in')}</RowHeaderItem>
          <RowHeaderItem>{formatMessage('orders.check_out')}</RowHeaderItem>
          <RowHeaderItem>{formatMessage('orders.price')}</RowHeaderItem>
          <RowHeaderItem>{formatMessage('orders.guests')}</RowHeaderItem>
        </OrderTableRowHeader>

        {listItems}

        <OrderTableRowFooter>
          <RowFooterItem
            onClick={this.handleOpenInfo}
          >
            {formatMessage('orders.more_about_order')}
          </RowFooterItem>

          <RowFooterItem
            isComments
            onClick={this.handleOpenComment}
          >
            {formatMessage('orders.comments')}
          </RowFooterItem>
        </OrderTableRowFooter>
      </>
    );
  }
}

OrderRow.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  filters: PropTypes.object
};

export default OrderRow;
