import { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const RuleWrapper = styled.div`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

@observer
class PolicyRules extends Component {
  static propTypes = {
    tariff: PropTypes.object.isRequired,
    cancellation_policy: PropTypes.object.isRequired
  };

  render() {
    const {
      cancellation_policy: { policy_rules },
      tariff: { billing_hour }
    } = this.props;

    let rules = _sortBy(policy_rules, (rule) => rule.deadline.amount);
    rules = rules.map((rule, idx) => {
      const {
        deadline: {
          amount: deadline_amount,
          unit: deadline_unit
        },
        penalty: {
          amount: penalty_amount,
          unit: penalty_unit
        }
      } = rule;

      return (
        <RuleWrapper key={idx}>
          <FormattedMessage
            id='tariffs.cancellation_policy.rule'
            values={{ billing_hour, deadline_amount, deadline_unit, penalty_amount, penalty_unit }}
          />
        </RuleWrapper>
      );
    });

    return <div>{rules}</div>;
  }
}

export default PolicyRules;
