import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cleanDeep from 'clean-deep';
import { Footer, FooterLeft, FooterRight, ButtonsWrapper, MessageWrapper } from './Buttons.styled';
import Message from 'Components/ui/Message';

import ConfirmModal from 'Components/ui/ConfirmModal';

import Button from 'Components/ui/Button';
import moment from 'moment';

const SOGAZ_ID = '5a27ea5273e8b0489f2768cb';

@inject('hotelStore')
@withRouter
@observer
class Buttons extends Component {
  @observable isOpenMessage = false;
  @observable openedConfirmModal = false;

  @computed get isValid() {
    const { form } = this.props;

    if (form.hasDuplicateRules) {
      return false;
    }

    if (form.editedPeriodId) {
      return false;
    }

    if (form.isOpenFormIndex || form.isAddStopSalePeriod) {
      return false;
    }

    if (form.editedPeriodId) {
      return false;
    }

    if (form.changed && !form.validating) {
      return form.isValid;
    }

    if (form.touched && !form.validating) {
      return form.isValid;
    }

    return form.focused || form.isValid;
  }

  componentDidMount() {
    const { hotelStore } = this.props;
    this.isOpenMessage = hotelStore.hotel.has_integration;
  }

  setOpenedConfirmModal = (flag) => {
    this.openedConfirmModal = flag;
  }

  @computed get showMessage() {
    const { form } = this.props;

    const source = form.$('source').values();

    if (!source?.id) return false;

    return source.id !== SOGAZ_ID;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { form } = this.props;

    if (form.hasDuplicateRules || form.editedPeriodId) {
      return false;
    }

    form.onSubmit(e, {
      onSuccess: this.successHandler,
      onError: this.errorHandler
    });
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.navigateToList();
  }

  successHandler = (form) => {
    const { tariff, updateTariff } = this.props;
    let data = cleanDeep({ ...form.values() }, { emptyArrays: false });

    if (data.contract?.from_date) {
      data.contract.from_date = moment(data.contract.from_date)
        .format('YYYY-MM-DDTHH:mm:ssZ');
    }

    if (form.periods.length === 0) {
      data = { ...data, periods: [] };
    } else {
      data = { ...data, periods: cleanDeep([...form.periods]) };
    }

    if (form.values().stop_sales.length === 0) {
      data = { ...data, stop_sales: [] };
    } else {
      data = { ...data, stop_sales: cleanDeep([...form.values().stop_sales]) };
    }

    this.isValid && updateTariff({ tariff, values: data })
      .then(data => this.navigateToList(data));
  }

  errorHandler = (form) => {
    console.log(form.errors());
  }

  navigateBack() {
    this.props.history.goBack();
  }

  handleCloseMessage = () => {
    this.isOpenMessage = false;
  }

  handleDestroy = async (e) => {
    const { tariff, removeTariff } = this.props;
    await removeTariff({ tariff });
    this.navigateToList();
  }

  navigateToList = (data) => {
    const { history } = this.props;
    history.push(`/tariffs/${data ? data.id : ''}`);
  };

  render() {
    const { tariff, isPending } = this.props;

    return (
      <Footer>
        {this.showMessage && this.isOpenMessage &&
          <MessageWrapper>
            <Message variant='warning' onClose={this.handleCloseMessage}>
              <span>
                Объект размещения подключен к менеджеру каналов TravelLine.
                <br />
                Любые изменения цен будут перезаписаны данными из канала.
              </span>
            </Message>
          </MessageWrapper>}

        {!this.isValid &&
          <p className='text error'>
            <FormattedMessage id='shared.check_for_errors' />
          </p>}

        <ButtonsWrapper>
          <FooterLeft>
            <Button
              size='large'
              loading={isPending}
              onClick={this.handleSubmit}
            ><FormattedMessage id='shared.confirm' />
            </Button>
            <Button
              size='large'
              variant='secondary'
              onClick={this.handleCancel}
            >
              <FormattedMessage id='shared.cancel' />
            </Button>
          </FooterLeft>

          <FooterRight>
            <Button
              size='large'
              variant='danger'
              onClick={() => this.setOpenedConfirmModal(true)}
            >
              <FormattedMessage id='shared.remove_tariff' />
            </Button>
          </FooterRight>
        </ButtonsWrapper>

        {this.openedConfirmModal && (
          <ConfirmModal
            title={<FormattedMessage id='shared.remove_tariff_ask' />}
            description={<FormattedMessage id='shared.remove_tariff_name_ask' values={{ name: tariff.name }} />}
            onApply={this.handleDestroy}
            onCancel={() => this.setOpenedConfirmModal(false)}
          />
        )}
      </Footer>
    );
  }
}

Buttons.propTypes = {
  form: PropTypes.object.isRequired,
  tariff: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotelStore: PropTypes.object.isRequired,
  updateTariff: PropTypes.func,
  removeTariff: PropTypes.func,
  isPending: PropTypes.bool
};

export default Buttons;
