import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Integration from './components/Integration';
import CalcQuotas from './components/CalcQuotas';
import TermsAccommodation from './components/TermsAccommodation';
import { Tabs } from '../../components/Tabs';
import InstantBooking from './components/InstantBooking';
import Loader from 'react-loaders';

const Integrations = styled.div`
  display: flex;
  gap: 34px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const Container = styled.div`
  width: fit-content;
  padding: 0.2rem 2.5rem;
`;

const LoaderContainer = styled.div``;

@inject('integrationsStore')
@observer
class Show extends Component {
  componentDidMount() {
    const { integrationsStore } = this.props;
    integrationsStore.fetch();
    integrationsStore.fetchSettings();
  }

  componentWillUnmount() {
    const { integrationsStore } = this.props;
    integrationsStore.clear();
  }

  render() {
    const { data, settings, state } = this.props.integrationsStore;
    const { isAdmin, handleOnClickTab, currentTab, TABS } = this.props;

    if (currentTab === 'instant_booking' && state === 'pending') {
      return (
        <LoaderContainer>
          <Loader className="loading" type="line-scale" active color="#999" />
        </LoaderContainer>
      );
    }

    return (
      <Container>
        <Tabs
          TABS={TABS}
          handleOnClickTab={handleOnClickTab}
          currentTab={currentTab}
        />
        <Wrapper>
          {currentTab === 'integrations' && (
            <Integrations>
              {data?.map((integration, index) => (
                <Integration
                  integration={integration}
                  key={index}
                  isAdmin={isAdmin}
                />
              ))}
            </Integrations>
          )}

          {currentTab === 'calc_quotas' && settings && (
            <CalcQuotas settings={settings.toJSON()} isAdmin={isAdmin} />
          )}
          {currentTab === 'accommodation' && settings && (
            <TermsAccommodation
              settings={settings.toJSON()}
              isAdmin={isAdmin}
            />
          )}
          {currentTab === 'instant_booking' && settings && (
            <InstantBooking settings={settings.toJSON()} isAdmin={isAdmin} />
          )}
        </Wrapper>
      </Container>
    );
  }
}

Show.propTypes = {
  integrationsStore: PropTypes.integrationsStore,
  isAdmin: PropTypes.bool,
  handleOnClickTab: PropTypes.func,
  currentTab: PropTypes.string,
  TABS: PropTypes.array
};

export default Show;
