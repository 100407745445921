import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Reservation from './Reservation';

@observer
class Reservations extends Component {
  render() {
    const { reservations } = this.props;

    const listItems = reservations
      .map(reservation => (
        <Reservation key={reservation.id} reservation={reservation} />
      ));

    return (
      <div className='section'>
        {listItems}
      </div>
    );
  }
}

Reservations.propTypes = {
  reservations: PropTypes.array.isRequired
};

export default Reservations;
