import { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { IntegrationsStore } from 'stores/IntegrationsStore';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import Show from './containers/Show';

const TABS = [
  {
    title: 'Интеграции',
    value: 'integrations'
  },
  {
    title: 'Расчет квот',
    value: 'calc_quotas'
  },
  {
    title: 'Условия размещения',
    value: 'accommodation'
  },
  {
    title: 'Мгновенное подтверждение',
    value: 'instant_booking'
  }
];

@withRouter
@observer
class Settings extends Component {
  state = {
    isAdmin: false,
    currentTab: TABS[0].value
  };

  adminModeHandler = () => {
    const { isAdmin } = this.state;

    this.setState({ isAdmin: !isAdmin });
    if (isAdmin) {
      Cookies.set('restriction', 'admin');
    } else {
      Cookies.remove('restriction');
    }
  };

  onClickTab = (tab) => () => {
    const { integrationsStore } = this.props;
    this.setState({ currentTab: tab }, () => {
      if (tab === 'instant_booking' || tab === 'calc_quotas' || tab === 'accommodation') {
        integrationsStore.fetchSettings();
      }
    });
  };

  render() {
    const { integrationsStore } = this.props;
    const { isAdmin, currentTab } = this.state;

    return (
      <Provider integrationsStore={integrationsStore}>
        <div className="layout__page">
          <TopBar isLoading={integrationsStore.isPending}>
            <TopBarContent adminModeHandler={this.adminModeHandler} />
          </TopBar>

          <div className="layout__page-content">
            <Show
              isAdmin={isAdmin}
              currentTab={currentTab}
              handleOnClickTab={this.onClickTab}
              TABS={TABS}
            />
          </div>
        </div>
      </Provider>
    );
  }
}

Settings.defaultProps = {
  integrationsStore: IntegrationsStore.create()
};

Settings.propTypes = {
  integrationsStore: PropTypes.integrationsStore
};

export default Settings;
