import { types, applySnapshot, applyPatch } from 'mobx-state-tree';
import instance from 'connection/instance';
import jsonpatch from 'fast-json-patch';
import _cloneDeep from 'lodash/cloneDeep';

import { Integration } from '../models/Integration';

const Settings = types.model('Settings', {
  ignore_room_type_capacity: types.maybe(types.boolean),
  fill_main_seats_for_no_bed: types.maybe(types.boolean),
  fill_extra_seats_for_no_bed: types.maybe(types.boolean),
  instant_booking: types.maybe(types.boolean)
});

export const IntegrationsStore = types.model(
  'IntegrationsStore',
  {
    data: types.optional(types.array(Integration), []),
    settings: types.maybe(Settings),
    state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

    get isFetched() {
      return this.state === 'done';
    },

    get isPending() {
      return this.state === 'pending';
    }
  },
  {
    fetch() {
      this.setState('pending');
      return instance
        .get('/api/hotel/integrations', {})
        .then((response) => this.updateStore(response))
        .then(() => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    fetchSettings() {
      this.setState('pending');
      return instance
        .get('/api/hotel/settings', {})
        .then((response) => this.updateStore(response))
        .then(() => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    toggleSettings(data) {
      this.setState('pending');
      return instance
        .put('/api/hotel/settings', {
          hotel: data
        })
        .then((response) => this.updateStore(response))
        .then(() => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    activate(id, source_id) {
      this.setState('pending');
      const integrations = _cloneDeep(this.data.toJSON());
      const integration = integrations.find(
        (integration) => integration.id === id
      );

      if (source_id) {
        const source = integration.sources.find(
          (source) => source.id === source_id
        );
        source.active = !source.active;
      }

      const source_ids = integration.sources
        .filter((source) => source.active)
        .map((source) => source.id);

      return instance
        .put(`/api/hotel/integrations/${id}/activate`, { data: { source_ids } })
        .then((response) => this.updateIntegrationStore(response))
        .then(() => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    deactivate(id) {
      this.setState('pending');
      return instance
        .put(`/api/hotel/integrations/${id}/deactivate`, {
          data: { source_ids: [] }
        })
        .then((response) => this.updateIntegrationStore(response))
        .then(() => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    clear() {
      const data = { data: undefined, state: undefined };
      applySnapshot(this, data);
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(this, data);
      }

      return data;
    },

    setState(state, data = undefined) {
      this.state = state;
      return data;
    },

    errorHandler(error) {
      this.setState('error');
      return Promise.reject(error);
    },

    updateStore(response) {
      const { status, data } = response;

      if (status === 200) {
        const patch = jsonpatch
          .compare(this.toJSON(), data)
          .filter((action) => action.op === 'replace' || action.op === 'add');

        applyPatch(this, patch);
      }

      return data;
    },

    updateIntegrationStore(response) {
      const { status, data } = response;

      if (status === 200) {
        const storeData = _cloneDeep(this.data.toJSON());
        const index = storeData.findIndex(
          (integration) => integration.id === data.data.id
        );
        storeData[index] = data.data;
        applySnapshot(this, { data: storeData });
      }

      return data;
    }
  }
);
