import { types } from 'mobx-state-tree';

export const Manager = types.model('Manager', {
  id: types.identifier(types.string),
  email: types.string,
  first_name: types.string,
  last_name: types.string
});

export const Source = types.model('Source', {
  id: types.identifier(types.string),
  name: types.string
});

export const Order = types.model('Order', {
  id: types.identifier(types.string),
  check_in: types.maybe(types.string),
  check_out: types.maybe(types.string),
  created_at: types.string,
  manager: Manager,
  order_code: types.string,
  price: types.frozen,
  source: Source,
  state: types.string,
  updated_at: types.string
});
